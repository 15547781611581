import React from "react";
import Publications from "../components/Publications";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
function PublicationPage() {
  return (
    <>
      <Nav />
      <Publications />
      <Footer/>
    </>
  );
}
export default PublicationPage;
