import React from "react";
import "../static/css/Team.css";
import Team_member from "../static/img/team_image.jpeg";
import { Link } from "react-router-dom";

function Team() {
  const teamMembers = [
    {
      name: "P S Senthil Kumaravel Pandian, ACS",
      role: "Associate Member",
      des: "Holds a bachelor's in commerce – Corporate Secretaryship, specializes in compliance, client education on Company Law/RBI updates, and ensuring adherence to secretarial standards. He emphasizes a conservative approach to maximize governance for clients.",
      bio: "Sri. P S Senthil Kumaravel Pandian is an Associate member of the Institute of Company Secretaries of India with a bachelor’s degree in commerce – Corporate Secretaryship. Having experience in technical scrutiny and undertake compliances with thoroughness and provide ongoing support to the clients. Also educate clients from time to time updates on Company Law / RBI, Compliance about the due dates and provide clarification on book keeping, maintenance of registers and records, filing requirements etc. further advises on enabling compliance with mandatory and voluntary secretarial standards. Always encouraged conservative interpretation of statutes to maximize governance standards to clients.",
      specializations: [
        "Compliance Management Services under Company Law, Foreign Exchange Management Laws and Securities Laws.",
        "Handled Secretarial audits, and other audits under Securities Laws and Listing regulations.",
        "Handled Due Diligence for Secretarial Compliance for Public and Private Limited Companies.",
        "Appears before RBI Officials with respect to NBFC Compliances.",
        "Handled RBI Compliance with respect to Annual FEMA Compliances and NBFC Compliances.",
        "Appears before Registrar of Companies - Ministry of Corporate Affairs for matters under the Companies Act, 2013.",
        "Appears before Regional Director-Ministry of Corporate Affairs for matters under the Companies Act, 2013.",
        "Appears before National Company Law Tribunal (NCLT) for matters under the Companies Act, 2013.",
        "Secretarial Auditor for Public and Private Companies.",
        "Secretarial Compliance Advisor for Public and Private Companies and Limited Liability Partnership (LLP).",
        "Corporate Law Compliance and Procedures.",
      ],
    },
    {
      name: "Karthika S, B.com (CS)",
      role: "Secretarial Executive",
      des: "Pursuing the Company Secretary course with a bachelor's in commerce – Corporate Secretaryship, is skilled in compliance and corporate governance. She is committed to supporting strategic goals and maintaining high ethical standards.",
      bio: "Ms. Karthika S is pursuing Company Secretary Course (professional programme) of Institute of Company Secretaries of India and holding a bachelor’s degree in commerce with corporate secretaryship. She is a dedicated Secretarial executive with a proven track record of ensuring statutory, regulatory, and legal compliance. Experienced in corporate governance, reporting and administrative assistance and skilled in effective communication, leadership, and meticulous planning. She is always willing to leverage a strong understanding of compliance, corporate governance to support strategic objectives and to maintain the highest ethical standards. She has been committed to excelling in corporate compliance and contributing to the success of the organization. As a Secretarial executive at S K P and CO, she has been responsible for handling corporate matters, including Incorporation of companies, compliance with the Companies Act 2013, GST registration, RBI Annual reporting, Secretarial document drafting, Adjudication, Compounding, and team management.",
      specializations: [
        "Oversaw the Incorporation of companies, ensuring adherence to legal and regulatory requirements.",
        "Managed compliance with the Companies Act, maintaining accurate and up-to-date records.",
        "Handled GST registration and annual reporting to RBI, ensuring timely and accurate submissions.",
        "Drafted various secretarial documents and ensured their accuracy and compliance.",
        "Managed adjudication and compounding processes, resolving regulatory issues effectively.",
        "Provided leadership and guidance in team management, ensuring smooth operations and collaboration.",
      ],
    },
  ];

  return (
    <div className="team-container">
      <div className="team-box">
        <div className="team-title">
          <h4>Meet Our Team</h4>
        </div>
        <div className="team-demo">
          <div className="team-members-grid">
            {teamMembers.map((member, index) => (
              <div className="card text-center" key={index}>
                <img
                  className="rounded-image"
                  src={Team_member}
                  alt="Card image cap"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    justifySelf: "center",
                    margin: "5px auto",
                  }}
                />
                <div className="card-body">
                  <h5 className="card-title">{member.name}</h5>
                  <h5 className="card-title">{member.role}</h5>
                  <p className="card-text">{member.des}</p>
                  <Link to={`/member/${index}`}>
                    <button className="btn-team">Read More</button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
