import React from "react";
import Services from "../components/Services";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import hero_img from "../static/img/service_header.jpg";
function ServicePage() {
  return (
    <>
      <Nav />
      <div className="hero-img">
        <img src={hero_img} alt="Hero Img" />
      </div>
      <Services />
      <Footer />
    </>
  );
}
export default ServicePage;
