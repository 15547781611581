import React from "react";
import { useParams } from "react-router-dom";
import ServiceHeader from "../components/ServiceHeader";
import ProductIntro from "../components/ProductIntro";
import Connect from "../components/Connect";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import adr from "../static/img/adr.png";
import ccm from "../static/img/ccm.png";
import lm from "../static/img/lm.png";
import ta from "../static/img/ta.png";
import dd from "../static/img/dd.png";
import ib from "../static/img/ib.png";
import crra from "../static/img/crra.png";
import as from "../static/img/as.png";
import ip from "../static/img/ip.png";
import aas from "../static/img/aas.png";
import adr2 from "../static/img/service1.png";
import ccm2 from "../static/img/ccm2.jpg";
import lm2 from "../static/img/lm2.jpg";
import ta2 from "../static/img/ta2.jpg";
import dd2 from "../static/img/dd2.jpg";
import ib2 from "../static/img/ib2.jpg";
import crra2 from "../static/img/crra2.jpg";
import as2 from "../static/img/as2.jpg";
import ip2 from "../static/img/ip2.jpg";
import aas2 from "../static/img/ip2.jpg";
import badr1 from "../static/img/badr1.png";
import badr2 from "../static/img/badr2.png";
import badr3 from "../static/img/badr3.png";
import badr4 from "../static/img/badr4.png";
import bccm1 from "../static/img/bccm1.png";
import bccm2 from "../static/img/bccm2.png";
import bccm3 from "../static/img/bccm3.png";
import bccm4 from "../static/img/bccm4.png";
import blm1 from "../static/img/blm1.png";
import blm2 from "../static/img/blm2.png";
import blm3 from "../static/img/blm3.png";
import blm4 from "../static/img/blm4.png";
import bta1 from "../static/img/bta1.png";
import bta2 from "../static/img/bta2.png";
import bta3 from "../static/img/bta3.png";
import bta4 from "../static/img/bta4.png";
import bcrra1 from "../static/img/bcrra1.png";
import bcrra2 from "../static/img/bcrra2.png";
import bcrra3 from "../static/img/bcrra3.png";
import bcrra4 from "../static/img/bcrra4.png";
import bdd1 from "../static/img/bdd1.png";
import bdd2 from "../static/img/bdd2.png";
import bdd3 from "../static/img/bdd3.png";
import bdd4 from "../static/img/bdd4.png";
import bib1 from "../static/img/bib1.png";
import bib2 from "../static/img/bib2.png";
import bib3 from "../static/img/bib3.png";
import bib4 from "../static/img/bib4.png";
import bas1 from "../static/img/bas1.png";
import bas2 from "../static/img/bas2.png";
import bas3 from "../static/img/bas3.png";
import bas4 from "../static/img/bas4.png";
import bip1 from "../static/img/bip1.png";
import bip2 from "../static/img/bip2.png";
import bip3 from "../static/img/bip3.png";
import bip4 from "../static/img/bip4.png";
import baas1 from "../static/img/Baas1.png";
import baas2 from "../static/img/Baas2.png";
import baas3 from "../static/img/Baas3.png";
import baas4 from "../static/img/Baas4.png";
import baas5 from "../static/img/Baas5.png";
import adrfooter from "../static/img/adr_footer.jpg";
import ccmfooter from "../static/img/ccmfooter.jpg";
import lmrfooter from "../static/img/lmrfooter.jpg";
import tafooter from "../static/img/tafooter.jpg";
import ddfooter from "../static/img/ddfooter.png";
import ibfooter from "../static/img/ibfooter.jpg";
import crrafooter from "../static/img/crrafooter.jpg";
import asfooter from "../static/img/asfooter.jpg";
import ipfooter from "../static/img/ipfooter.jpg";
import aasfooter from "../static/img/aasfooter.jpg";

const services = [
  {
    image: adr,
    title: "Alternative Dispute Resolution",
    description:
      "In present times, early resolution of a dispute not only saves valuable time and money of the parties to the dispute but also promotes the environment for enforcement of contract and ease of doing business. The traditional mode of dispute resolution i.e. litigation is a lengthy process leading to unnecessary delays in dispensation of justice as well as over-burdening the Judiciary. ",

    img: adr2,
    why: "Why Choose Alternative Dispute Resolution?",
    whyItems: [
      {
        title: "Time-Efficient",
        description:
          "Expedite the resolution process, avoiding the lengthy timelines of litigation.",
      },
      {
        title: "Cost-Effective",
        description:
          "Reduce legal fees and associated costs compared to traditional court proceedings.",
      },
      {
        title: "Confidentiality",
        description:
          " Keep sensitive matters private, as ADR processes are typically not part of the public record.",
      },
      {
        title: "Control",
        description:
          "Allow parties to have a say in the selection of the mediator or arbitrator, tailoring the process to their needs.",
      },
      {
        title: "Flexible Solutions",
        description:
          "Craft customized resolutions that meet the specific interests of all parties involved.",
      },
    ],
    benefits: "Key Benefits of Our Alternative Dispute Resolution:",
    benefitsContent: [
      {
        img: badr1,
        title: "Reduced Stress",
        description:
          "Minimize the emotional strain often associated with adversarial legal proceedings.",
      },
      {
        img: badr2,
        title: "Preserved Relationships",
        description:
          "Promote cooperation and communication, helping to maintain business or personal relationships post-dispute.",
      },
      {
        img: badr3,
        title: " Informed Outcomes ",
        description:
          "Engage neutral third parties with expertise in the relevant field for fair and knowledgeable resolution.",
      },
      {
        img: badr4,
        title: "Greater Satisfaction",
        description:
          "Achieve more satisfactory results as parties can actively participate in creating their solutions.",
      },
    ],
    types:
      "We help our clients to get better and timely resolution for the dispute, which includes:",
    typesItems: [
      {
        title: "Advising on arbitration, negotiations and conciliation.",
      },
      {
        title: "Drafting Arbitration/Conciliation Agreement/Clauses.",
      },
      {
        title:
          "Acting as Arbitrator/Conciliator in Domestic and International Commercial disputes.",
      },
    ],
    elevateHead: "Ready to Resolve Disputes Effectively?",
    elevateContent:
      "Embrace the power of Alternative Dispute Resolution to handle conflicts swiftly and amicably. Contact us today to discover how our ADR services can benefit you!",
    footerimg: adrfooter,
  },
  {
    image: ccm,
    title: "Corporate Compliance Management",
    description:
      "Corporate Compliance management is centered on ensuring that an organization is aligned to and conforms to applicable laws, regulations, and organizational ethics, standards, and policies. We assist our clients to follow good corporate governance practices by ensuring they are in compliance with various applicable laws and regulations.",
    img: ccm2,
    why: "Our service includes:",
    whyItems: [
      {
        title: "Annual Compliance:",
        description:
          "These are the regular, yearly filings and disclosures companies should comply, including submitting annual returns and financial statements.",
      },
      {
        title: "Event-Based Compliance: ",
        description:
          "These are specific compliances that need to be addressed as and when certain events occur within the company, such as changes in the company's management, share capital, or registered office.",
      },
      {
        title: "Other Compliances: ",
        description:
          "This category includes a range of other regulatory obligations that might not fall under annual or event-based compliance but are essential for maintaining the company’s legal status, such as director’s KYC updation, maintenance of statutory registers and minutes. ",
      },
    ],
    benefits: "Key Benefits of Our Compliance Management",
    benefitsContent: [
      {
        img: bccm1,
        title: "Protect Against Legal Issues",
        description:
          " Reduce the likelihood of lawsuits and fines through adherence to regulatory requirements.",
      },
      {
        img: bccm2,
        title: "Promote Ethical Culture",
        description:
          "Foster an environment where ethical behavior and compliance are prioritized.",
      },
      {
        img: bccm3,
        title: "Boost Employee Morale",
        description:
          "Encourage a sense of safety and security among employees, knowing they work for a compliant organization.",
      },
      {
        img: bccm4,
        title: "Facilitate Training and Awareness",
        description:
          " Regular compliance training ensures all employees understand policies and regulations.",
      },
    ],
    types: "Our service includes:",
    typesItems: [
      {
        title: "Annual Compliance",
        description:
          "These are the regular, yearly filings and disclosures companies should comply, including submitting annual returns and financial statements.",
      },
      {
        title: "Event-Based Compliance",
        description:
          "These are specific compliances that need to be addressed as and when certain events occur within the company, such as changes in the company's management, share capital, or registered office.",
      },
      {
        title: "Other Compliances",
        description:
          "This category includes a range of other regulatory obligations that might not fall under annual or event-based compliance but are essential for maintaining the company’s legal status, such as director’s KYC updation, maintenance of statutory registers and minutes. ",
      },
      {
        description:
          "We assist our clients to follow good corporate governance practices by ensuring they are in compliance with various applicable laws and regulations.",
      },
    ],
    elevateHead: "Ready to Strengthen Your Compliance Management?",
    elevateContent:
      "Empower your organization with robust Corporate Compliance Management practices that safeguard your operations and promote ethical conduct. Contact us today to learn how we can help!",
    footerimg: ccmfooter,
  },
  {
    image: lm,
    title: "Legal Management and Representation",
    description:
      "We are responsible for ensuring our clients comply with standard legal practice and maintain good standards of corporate governance.",
    img: lm2,
    why: "Here at SKP and Co we deal with matters required to be represented before the various Authorities such as",
    whyItems: [
      {
        title: "National Company Law Tribunal",
      },
      {
        title: "Registrar of Companies",
      },
      {
        title: "Regional Director",
      },
      {
        title: "Official Liquidator",
      },
      {
        title: "Stock Exchanges",
      },
    ],
    benefits: "Key Benefits of Effective Legal Management:",
    benefitsContent: [
      {
        img: blm1,
        title: "Streamlined Processes",
        description:
          "Improve efficiency in handling legal matters, from contract reviews to dispute resolutions.",
      },
      {
        img: blm2,
        title: "Enhanced Reputation",
        description:
          "Build trust with stakeholders through transparent and ethical legal practices.",
      },
      {
        img: blm3,
        title: "Proactive Legal Strategies",
        description:
          "Anticipate legal challenges and implement strategies to address them proactively.",
      },
      {
        img: blm4,
        title: "Informed Decision-Making",
        description:
          " Equip leadership with legal insights that support sound business strategies and operations.",
      },
    ],
    types: "Core Components of LMR",
    typesItems: [
      {
        title: "Contract Management",
        description:
          "Develop, review, and negotiate contracts to protect organizational interests and minimize risk.",
      },
      {
        title: "Litigation Management",
        description:
          "Oversee and coordinate legal disputes, ensuring effective representation in court or alternative dispute resolution settings.",
      },
      {
        title: "Regulatory Compliance",
        description:
          "Monitor and ensure compliance with relevant laws, regulations, and industry standards.",
      },
      {
        title: "Risk Assessment",
        description:
          "Conduct thorough assessments to identify potential legal risks and develop mitigation strategies.",
      },
      {
        title: "Training and Development",
        description:
          "Provide training for employees on legal issues, compliance, and ethical standards to foster a culture of awareness.",
      },
    ],
    elevateHead: "Ready to Strengthen Your Legal Management?",
    elevateContent:
      "Empower your organization with comprehensive Legal Management and Representation services that protect your interests and promote compliance. Contact us today to discover how we can support your legal needs!",
    footerimg: lmrfooter,
  },
  {
    image: ta,
    title: "Transaction Advisory",
    description:
      "We at SKP and CO advise clients on how to structure the deal, negotiate the terms, prepare the documentation, and comply with the regulations in case of merger, acquisition or divestiture.",
    img: ta2,
    why: "Why Choose Our Transaction Advisory Services?",
    whyItems: [
      {
        title: "Expert Guidance",
        description:
          "Leverage the expertise of seasoned professionals who understand the intricacies of financial transactions.",
      },
      {
        title: "Risk Mitigation",
        description:
          "Identify and address potential risks before they impact your deal.",
      },
      {
        title: "Valuation Excellence",
        description:
          "Ensure accurate and fair valuation of assets, liabilities, and businesses for informed decision-making.",
      },
      {
        title: "Due Diligence Support",
        description:
          "Conduct thorough due diligence to uncover vital information that informs transaction strategies.",
      },
      {
        title: "Post-Transaction Integration",
        description:
          "Assist in seamless integration post-deal to realize synergies and optimize performance.",
      },
    ],
    benefits: "Benefits of Transaction Advisory Services",
    benefitsContent: [
      {
        img: bta1,
        title: "Tailored Solutions",
        description:
          " Customized advisory services to meet the unique needs of each transaction.",
      },
      {
        img: bta2,
        title: "Informed Decision-Making",
        description:
          "Data-driven insights that empower stakeholders to make well-informed choices.",
      },
      {
        img: bta3,
        title: " Increased Efficiency",
        description:
          "Streamlined processes that save time and resources during critical transaction phases.",
      },
      {
        img: bta4,
        title: "Holistic Approach",
        description:
          "Comprehensive support covering all transaction stages, from planning to execution and beyond.",
      },
    ],
    types: "Our area of practice includes",
    typesItems: [
      {
        title: "Valuation of Business.",
      },
      {
        title: "Due diligence support.",
      },
      {
        title: "Risk analysis.",
      },
      {
        title: "Assessing the financial health of the target company.",
      },
      {
        title: "Litigation support.",
      },
      {
        title:
          "Planning strategies for amalgamation/merger, acquisition, takeover, spin off, reconstruction, re-organisation, restructuring and winding up of companies.",
      },
      {
        title:
          "Drafting schemes of amalgamation or arrangement, public offer for acquisition or takeover, and Promoters Agreement.",
      },
      {
        title: "Complying with necessary legal and procedural requirements.",
      },
      {
        title: "Advising the management on post restructured scenario.",
      },
      {
        description:
          "We at SKP and CO advise clients on how to structure the deal, negotiate the terms, prepare the documentation, and comply with the regulations in case of merger, acquisition or divestiture.",
      },
    ],
    elevateHead: "Ready to Elevate Your Transactions?",
    elevateContent:
      "Partner with us for comprehensive Transaction Advisory Services that not only guide you through complex transactions but also enhance your business value. Contact us today to learn more!",
    footerimg: tafooter,
  },
  {
    image: dd,
    title: "Due Diligence Audits",
    description:
      "Due diligence is the investigation or exercise of care that a business or person is normally expected to undertake before entering into an agreement or contract with another party. This proactive approach is employed to vet issues that can potentially affect the business and  preventing major problems in the enterprise.",
    img: dd2,
    why: "Due diligence can be carried out during the following corporate events:",
    whyItems: [
      {
        title: "Mergers and acquisitions",
      },
      {
        title: "Corporate Restructuring",
      },
      {
        title: "Joint Venture",
      },
      {
        title: "Partnerships",
      },
      {
        title: "Collaborations",
      },
    ],
    benefits: "Key Benefits of a Due Diligence Audit",
    benefitsContent: [
      {
        img: bdd1,
        title: "Enhanced Transparency",
        description:
          "Foster trust between parties through thorough examination and validation of information.",
      },
      {
        img: bdd2,
        title: "Improved Negotiation Position",
        description:
          "Strengthen your negotiating power by understanding the target company’s strengths and weaknesses.",
      },
      {
        img: bdd3,
        title: " Strategic Insights",
        description:
          "Gain valuable insights into operational efficiencies, market position, and competitive landscape.",
      },
      {
        img: bdd4,
        title: "Long-Term Success",
        description:
          "Reduce the likelihood of future disputes or financial losses through proactive risk management.",
      },
    ],
    types: "Core Components of a DDA",
    typesItems: [
      {
        title: "Financial Analysis",
        description:
          "Review financial statements, cash flow, and forecasts to assess fiscal health.",
      },
      {
        title: "Operational Review",
        description:
          "Evaluate business operations, processes, and management practices for efficiency and effectiveness.",
      },
      {
        title: "Legal Compliance",
        description:
          "Examine contracts, licenses, and regulatory obligations to identify potential legal issues",
      },
      {
        title: "Market Assessment",
        description:
          "Analyze market conditions, competitors, and industry trends affecting the target company.",
      },
      {
        title: "Cultural Fit",
        description:
          "Assess the organizational culture to ensure alignment with your own company’s values and practices.",
      },
    ],
    elevateHead: "Ready to Conduct a Due Diligence Audit?",
    elevateContent:
      "We carry on due diligence including but not limited to Financial, Legal, Secretarial, Asset, Human Resource, Environmental, Intellectual property.",
    footerimg: ddfooter,
  },
  {
    image: ib,
    title: "Insolvency and Bankruptcy Practice",
    description:
      "Insolvency and Bankruptcy Practice refers to the specialized area of law and financial services that deals with the legal proceedings and financial management processes surrounding individuals or businesses that are unable to meet their debt obligations. This practice aims to provide solutions for debt recovery, asset management, and reorganization to ensure the best possible outcomes for all parties involved.",
    img: ib2,
    why: "Liquidation",
    whyItems: [
      {
        description:
          "Liquidation is the formal means of closing a company when there are still assets and liabilities which need to be dealt with. These assets need to be sold and redistributed to the creditors and shareholders of the insolvent business.",
      },
      {
        description:
          "Your business may face liquidation if you have cash flow issues regularly, and creditors are threatening to take action. ",
      },
    ],
    benefits: "Key Benefits of Insolvency and Bankruptcy Practice:",
    benefitsContent: [
      {
        img: bib1,
        title: "Expert Representation",
        description:
          "Access to experienced legal and financial professionals who understand the intricacies of insolvency law.",
      },
      {
        img: bib2,
        title: "Risk Mitigation",
        description:
          " Identify potential risks early and implement strategies to minimize legal and financial exposure.",
      },
      {
        img: bib3,
        title: " Cost Efficiency",
        description:
          "Explore alternatives to bankruptcy, potentially saving clients time and money through negotiation and mediation.",
      },
      {
        img: bib4,
        title: "Empowerment",
        description:
          "Provide clients with the knowledge and support they need to make informed decisions about their financial futures.",
      },
    ],
    types: "Core Components of Insolvency and Bankruptcy Practice",
    typesItems: [
      {
        title: "Bankruptcy Filing",
        description:
          "Guide clients through the bankruptcy filing process, including Chapter 7 and Chapter 11 filings, to achieve the best outcome.",
      },
      {
        title: "Debt Restructuring",
        description:
          " Develop and implement debt restructuring plans to improve cash flow and reduce liabilities.",
      },
      {
        title: "Creditor Negotiation",
        description:
          "Represent clients in negotiations with creditors to secure favorable repayment terms or settlements.",
      },
      {
        title: "Asset Liquidation",
        description:
          "Manage the liquidation of assets in a way that maximizes returns for creditors while protecting debtor interests.",
      },
      {
        title: " Financial Advisory Services",
        description:
          "Provide ongoing financial advice and planning to help clients rebuild after insolvency.",
      },
    ],
    elevateHead: "Ready to Navigate Insolvency and Bankruptcy Challenges?",
    elevateContent:
      "Empower your financial future with professional Insolvency and Bankruptcy Practice that prioritizes your interests and aims for sustainable recovery. Contact us today to learn how we can assist you!",
    footerimg: ibfooter,
  },
  {
    image: ip,
    title: "Intellectual Property Rights",
    description:
      "Intellectual Property rights mean providing rights through patents, copyrights and trademarks. Holders of intellectual property rights have a monopoly on the usage of property or items for a specified time period.",
    img: ip2,
    why: "The Common types of intellectual property are listed below",
    whyItems: [
      {
        title: "Patents",
        description:
          "It is used for protecting new inventions, ideas, or processes. Patent holders need to pay periodic government renewal fees. An approved patent is for a limited time period. ",
      },
      {
        title: "Copyrights",
        description:
          "It protects the ideas, examples would be written works, music, art, etc.",
      },
      {
        title: "Trademarks ",
        description:
          "It protects the symbols, colors, phrases, sounds, design etc. ",
      },
      {
        title: "Trade Secrets ",
        description:
          "It may be strategies, systems, formulas, or other confidential information of an organization that provides them with a competitive advantage in the market.",
      },
    ],
    benefits: "Benefits of Understanding Intellectual Property Rights",
    benefitsContent: [
      {
        img: bip1,
        title: " Legal Protection",
        description:
          "Secure legal recourse against infringement, ensuring that creators can defend their rights effectively.",
      },
      {
        img: bip2,
        title: "Increased Market Value",
        description:
          "Enhance the overall value of a business by having a robust portfolio of protected intellectual assets.",
      },
      {
        img: bip3,
        title: "Strategic Positioning",
        description:
          "Leverage IP assets as part of business strategy to differentiate from competitors and capture market share.",
      },
      {
        img: bip4,
        title: "Global Opportunities",
        description:
          "Navigate international markets more effectively by understanding and protecting IP rights globally.",
      },
    ],
    types: "The Common types of intellectual property are listed below",
    typesItems: [
      {
        title: "Patents",
        description:
          "It is used for protecting new inventions, ideas, or processes. Patent holders need to pay periodic government renewal fees. An approved patent is for a limited time period.",
      },
      {
        title: "Trademarks",
        description:
          "It protects the symbols, colors, phrases, sounds, design etc.",
      },
      {
        title: "Copyrights",
        description:
          " It protects the ideas, examples would be written works, music, art, etc.",
      },
      {
        title: "Trade Secrets",
        description:
          "It may be strategies, systems, formulas, or other confidential information of an organization that provides them with a competitive advantage in the market.",
      },
    ],
    elevateHead: "Why is Intellectual Property Important?",
    elevateContent:
      "Intellectual Property Rights are important to stimulate and promote research and development. If the inventions and ideas of individuals and organizations are not protected then the concerned people or organizations will not reap the benefits of their hard work and naturally.",
    footerimg: ipfooter,
  },
  {
    image: crra,
    title: "Corporate Regulatory Risk Assessment",
    description:
      "Corporate Regulatory Risk Assessment is the process of identifying, analyzing, and addressing potential regulatory risks that could impact an organization. This assessment ensures that companies stay compliant with laws and regulations, avoiding penalties, while maintaining operational integrity and stakeholder trust.",
    img: crra2,
    why: "Why Is Corporate Regulatory Risk Assessment Essential?",
    whyItems: [
      {
        title: "Proactive Risk Mitigation",
        description:
          "Identify and address regulatory risks before they escalate into significant issues.",
      },
      {
        title: "Regulatory Compliance",
        description:
          "Ensure compliance with all relevant laws, preventing legal and financial repercussions.",
      },
      {
        title: "Informed Decision-Making",
        description:
          "Enable leadership to make strategic decisions based on a comprehensive understanding of risks.",
      },
      {
        title: "Operational Safeguards",
        description:
          "Protect the organization’s operational processes by aligning them with compliance requirements.",
      },
      {
        title: "Reputation Management",
        description:
          "Enhance the organization's reputation by demonstrating commitment to compliance and ethical standards.",
      },
    ],
    benefits: "Key Benefits of a Corporate Regulatory Risk Assessment",
    benefitsContent: [
      {
        img: bcrra1,
        title: "Comprehensive Risk Insight",
        description:
          "Provides a thorough understanding of regulatory risks affecting the business.",
      },
      {
        img: bcrra2,
        title: "Targeted Resource Allocation",
        description:
          "Helps allocate resources efficiently to address the most critical risks.",
      },
      {
        img: bcrra3,
        title: "Continuous Improvement",
        description:
          " Fosters a culture of ongoing compliance and risk management improvement.",
      },
      {
        img: bcrra4,
        title: "Audit Readiness",
        description:
          "Enhances preparedness for regulatory audits through structured planning and assessment.",
      },
    ],
    types: "Core Steps in a Corporate Regulatory Risk Assessment",
    typesItems: [
      {
        title: "Risk Identification",
        description:
          "Evaluate all regulatory requirements relevant to the organization and identify potential risks.",
      },
      {
        title: "Risk Evaluation",
        description:
          " Assess the likelihood and impact of each risk on the organization’s compliance and operational goals.",
      },
      {
        title: "Control Review",
        description:
          "Examine the existing controls and safeguards to ensure they effectively mitigate identified risks.",
      },
      {
        title: "Mitigation Planning",
        description:
          " Develop actionable strategies to manage, reduce, or eliminate risks.",
      },
      {
        title: "Monitoring and Reporting",
        description:
          "Establish ongoing processes to monitor risks and report on compliance status to stakeholders.",
      },
    ],
    elevateHead: "Ready to Protect Your Business from Regulatory Risks?",
    elevateContent:
      "A Corporate Regulatory Risk Assessment is essential for maintaining compliance and safeguarding your organization from potential legal risks. Contact us today to see how we can help you manage regulatory risk effectively!",
    footerimg: crrafooter,
  },
  {
    image: as,
    title: "Advisory Services",
    description:
      "We at SKP and CO provide advisory services for the formation and management of your business in an efficient way.",
    img: as2,
    why: "CORPORATE LAWS ADVISORY SERVICES COMPANIES ACT",
    whyItems: [
      {
        description:
          "Filing, registering, representing, attesting or verifying any document including forms, returns and applications by or on behalf of the company as an authorized representative.",
      },
      {
        description:
          "Compilation of status/search reports for companies, banks and financial institutions.",
      },
      {
        description:
          "Pre-certification of forms relating to Registration/Modification/ Satisfaction of charges and their filing with the Registrar of Companies.",
      },
      {
        description:
          "Pre-certification of other documents and returns required to be filed with the Registrar of Companies.",
      },
      {
        description: "Advising on legal and procedural matters under the Act.",
      },
    ],
    benefits: "Key Benefits of Advisory Services",
    benefitsContent: [
      {
        img: bas1,
        title: "Tailored Solutions",
        description:
          "Customized approaches that address your specific challenges and organizational needs.",
      },
      {
        img: bas2,
        title: "Increased Efficiency",
        description:
          "Streamline operations and eliminate inefficiencies through expert analysis and recommendations.",
      },
      {
        img: bas3,
        title: "Enhanced Decision-Making",
        description:
          " Equip leadership with the insights necessary to make strategic and impactful decisions.",
      },
      {
        img: bas4,
        title: "Improved Accountability",
        description:
          "Foster a culture of accountability and transparency within your organization.",
      },
    ],
    types: "RAISING OF RESOURCES",
    typesItems: [
      {
        title: "Advisor/Consultant in issue of shares and other securities.",
      },
      {
        title:
          "Drafting of prospectus/ offer for sale/ letter of offer/ other documents related to issue of securities and obtaining various approvals in association with lead managers.",
      },
      {
        title:
          "Listing of securities/delisting of securities with recognized stock exchanges.",
      },
      {
        title: "Private placement of shares and securities.",
      },
      {
        title: "Buy-back of shares and other securities.",
      },
      {
        title: "Raising of funds from international markets - ADR/GDR/ECB.",
      },
    ],
    elevateHead: "Ready to Elevate Your Organization?",
    elevateContent:
      "Engaging Advisory Services is essential for navigating complexities and unlocking your organization’s full potential. Contact us today to discover how our expertise can support your strategic objectives!",
    footerimg: asfooter,
  },
  {
    image: aas,
    title: "Forex Advisory Services",
    description:
      "Forex advisory services offer expert guidance in currency trading, providing tailored strategies, risk management, and real-time market insights. These services help clients minimize currency risks and enhance profit potential, making them essential for navigating the complexities of the forex market.",
    img: aas2,
    why: "Why Are Forex Advisory Services Important?",
    whyItems: [
      {
        title: "Risk Protection",
        description:
          "Shields against currency volatility and stabilizes finances.",
      },
      {
        title: "Expert Insights",
        description: "Provides critical market analysis for smarter decisions.",
      },
      {
        title: "Tailored Strategies",
        description:
          "Aligns trading plans with individual goals and risk levels.",
      },
      {
        title: "Profit Maximization",
        description:
          "Enhances returns through strategic guidance and precise execution.",
      },
    ],
    benefits: "Key Benefits of Forex Advisory Services",
    benefitsContent: [
      {
        img: baas1,
        title: "Effective Risk Management",
        description:
          "Minimize losses with tailored strategies to protect against currency fluctuations.",
      },
      {
        img: baas2,
        title: "Informed Decision-Making",
        description:
          "Get expert analysis and insights for smarter, data-driven trades.",
      },
      {
        img: baas3,
        title: "Personalized Investment Plans",
        description:
          "Receive strategies customized to your financial goals and risk profile.",
      },
      {
        img: baas4,
        title: "Advanced Trading Tools",
        description:
          "Access cutting-edge tools and technology for precise, efficient trading.",
      },
      {
        img: baas5,
        title: "Maximized Profit Potential",
        description:
          "Enhance returns through strategic advice and optimized trade execution.",
      },
    ],

    typesItems: [
      {
        title: "Foreign Exchange Management Act",
        description:
          "Advising on legal and procedural matters under FEMA, including guidance for Non-Resident Indians on investment and repatriation in India, and obtaining necessary approvals from RBI/FIPB.",
      },
      {
        title: "Foreign Collaborations and Overseas Joint Ventures",
        description:
          "Advising on foreign collaborations, including setting up Indian subsidiaries, overseas joint ventures, and drafting key agreements like MOUs, Promoters, Shareholders, and Commercial Agreements.",
      },
      {
        title: "Export-Import & Forex",
        description:
          "Providing guidance on Export-Import policy and regulations, including IEC application and renewal, documentation, Letters of Credit, and assistance with foreign currency transactions.",
      },
    ],
    elevateHead: "Ready to Strengthen Your Financial Integrity?",
    elevateContent:
      "Audit and Assurance services are vital for ensuring the accuracy and reliability of your financial information. Contact us today to explore how our expert services can add value to your organization!",
    footerimg: aasfooter,
  },
];

function ProductPage() {
  const { id } = useParams();
  const index = parseInt(id, 10);

  const service = services[index] || services[0];
  const {
    image,
    title,
    description,
    img,
    why,
    whyItems,
    benefits,
    benefitsContent,
    types,
    typesItems,
    elevateHead,
    elevateContent,
    footerimg,
  } = service;

  return (
    <>
      <Nav />
      <ServiceHeader image={image} title={title} description={description} />
      <ProductIntro
        img={img}
        why={why}
        whyContent={whyItems}
        benefits={benefits}
        benefitsContent={benefitsContent}
        types={types}
        typesContent={typesItems}
        elevate_head={elevateHead}
        elevate_content={elevateContent}
        footerimg={footerimg}
      />
      <Connect />
      <Footer />
    </>
  );
}

export default ProductPage;
