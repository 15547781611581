import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ServiceHeader from "../components/ServiceHeader";
import events_img from "../static/img/Events.jpg";
import "../static/css/Events.css";

function Events() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const months = [];
  for (let i = 0; i < 12; i++) {
    const monthIndex = (currentMonth + i) % 12;
    const year = currentYear + Math.floor((currentMonth + i) / 12);
    months.push({
      name: new Date(year, monthIndex).toLocaleString("default", {
        month: "long",
      }),
      year: year,
    });
  }

  const events = {
    "October 2024": [
      {
        date: "2024-10-15",
        time: "14:00",
        title: "Corporate Tax Seminar",
        description:
          "Understanding the latest changes in corporate taxation and compliance requirements.",
      },
    ],
    "December 2024": [
      {
        date: "2024-12-05",
        time: "10:00",
        title: "Annual Compliance Workshop",
        description:
          "Essential updates on regulatory compliance and best practices for businesses.",
      },
      {
        date: "2024-12-20",
        time: "15:30",
        title: "Year-End Tax Planning Session",
        description:
          "Strategic planning session for optimizing tax positions before year end.",
      },
    ],
    "January 2025": [
      {
        date: "2025-01-10",
        time: "09:30",
        title: "New Year Business Strategy Meeting",
        description:
          "Comprehensive review and planning session for the upcoming fiscal year.",
      },
      {
        date: "2025-01-15",
        time: "11:00",
        title: "Legal Updates Seminar",
        description:
          "Key insights on recent legal developments affecting businesses.",
      },
      {
        date: "2025-01-22",
        time: "14:00",
        title: "Risk Management Workshop",
        description:
          "Interactive session on identifying and mitigating business risks.",
      },
      {
        date: "2025-01-28",
        time: "16:00",
        title: "Financial Planning Conference",
        description:
          "Expert guidance on financial strategies and market outlook.",
      },
    ],
  };

  return (
    <>
      <Nav />
      <ServiceHeader
        image={events_img}
        title="Upcoming Events"
        description="Stay updated with our latest events and programs"
      />
      <div className="events-container">
        {months.map(({ name, year }) => (
          <div key={`${name}-${year}`} className="month-section">
            <h2>{`${name} ${year}`}</h2>
            <div className="events-grid">
              {events[`${name} ${year}`]?.map((event, index) => (
                <div key={index} className="event-card">
                  <div className="event-date">
                    {new Date(event.date).getDate()}
                    <span>
                      {new Date(event.date).toLocaleString("default", {
                        month: "short",
                      })}
                    </span>
                  </div>
                  <div className="event-details">
                    <h3>{event.title}</h3>
                    <p className="event-time">{event.time}</p>
                    <p className="event-description">{event.description}</p>
                  </div>
                </div>
              )) || (
                <div className="no-events">
                  <p>No events scheduled</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}

export default Events;
