import React from "react";
import Contact from "../components/Contact";
import Header from "../components/Header";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
function PublicationPage() {
  return (
    <>
      <Nav />
      <Header />
      <Contact />
      <Footer/>
    </>
  );
}
export default PublicationPage;
