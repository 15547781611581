import React, {  useState } from "react";
import AdminNav from "../components/AdminNav";
import AdminHeader from "../components/AdminHeader.jsx";
import "../static/css/AdminControl.css"
function PublicationPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  return (
    <>
      <div className="Admin-mainbox">
        <AdminNav />
        <div className="Admin-contentbox">
          <AdminHeader />
          <div className="admincontrol-form">
            <form>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <label htmlFor="mail">Mail</label>
              <input
                type="mail"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <label htmlFor="password">Password</label>
              <input
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <button>Update</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default PublicationPage;
