import React, { useState } from "react";
import "../static/css/AdminNav.css";
import { Link } from "react-router-dom";

function AdminNav() {
  return (
    <>
      <div className="admin-Nav">
        <div className="adminNav-title">
          <h3>KSP & Co</h3>
        </div>
        <div className="adminNav-button">
          <Link to="/admincontrol">Admin Users</Link>
          <Link to="/adminblog">Blogs</Link>
          <Link to="/adminschesule">Scheduling</Link>
          <Link to="/admingallery">Gallery</Link>
          <Link to="/adminteam">Team Members</Link>
        </div>
      </div>
    </>
  );
}

export default AdminNav;
