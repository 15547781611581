import React from "react";
import Team from "../components/Team";
import Intro from "../components/Intro";
import Connect from "../components/Connect";
import ServiceHeader from "../components/ServiceHeader";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import team_img from "../static/img/team.png";
function TeamPage() {
  return (
    <>
      <Nav />
      <ServiceHeader
        image={team_img}
        title={"People at SKP&Co"}
        description={""}
      />
      <Team />
      <Intro />
      <Connect />
      <Footer />
    </>
  );
}
export default TeamPage;
