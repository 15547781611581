import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer";
import myImage from "../static/img/data.jpeg";
import "../static/css/publicationDetail.css";

const publicationDetails = {
  "Daily Regulatory Updates": {
    cards: [
      {
        id: 1,
        title: "MAS New Guidelines",
        imgSrc: myImage,
        description:
          "Latest updates on MAS regulatory changes affecting financial advisers.",
        date: "2024-03-15",
      },
      {
        id: 2,
        title: "Compliance Updates",
        imgSrc: myImage,
        description:
          "Recent changes in compliance requirements for insurance professionals.",
        date: "2024-03-14",
      },
      {
        id: 3,
        title: "Policy Changes",
        imgSrc: myImage,
        description:
          "Important policy updates and amendments for financial services sector.",
        date: "2024-03-12",
      },
      {
        id: 4,
        title: "Industry Standards",
        imgSrc: myImage,
        description:
          "New standards and best practices for financial advisory services.",
        date: "2024-03-10",
      },
    ],
  },
  Downloads: {
    cards: [
      {
        id: 1,
        title: "2024 Forms Package",
        imgSrc: myImage,
        description:
          "Complete set of updated forms for financial advisory services.",
        date: "2024-03-01",
      },
      {
        id: 2,
        title: "Compliance Checklist",
        imgSrc: myImage,
        description: "Essential compliance documentation and guidelines.",
        date: "2024-03-10",
      },
      {
        id: 3,
        title: "Forms",
        imgSrc: myImage,
        description: "Forms for financial advisory services.",
        date: "2024-03-01",
      },
    ],
  },
  "IAFA News": {
    cards: [
      {
        id: 1,
        title: "Annual Conference 2024",
        imgSrc: myImage,
        description:
          "Highlights from this year's IAFA annual conference and key takeaways.",
        date: "2024-03-20",
      },
      {
        id: 2,
        title: "Member Achievements",
        imgSrc: myImage,
        description: "Celebrating recent accomplishments of IAFA members.",
        date: "2024-03-18",
      },
      {
        id: 3,
        title: "IAFA News",
        imgSrc: myImage,
        description: "Latest news and updates from IAFA.",
        date: "2024-03-16",
      },
    ],
  },
  Lighthouse: {
    cards: [
      {
        id: 1,
        title: "Industry Trends 2024",
        imgSrc: myImage,
        description:
          "In-depth analysis of emerging trends shaping the insurance and financial advisory landscape.",
        date: "2024-03-22",
      },
      {
        id: 2,
        title: "Market Outlook",
        imgSrc: myImage,
        description:
          "Comprehensive review of market conditions and future projections.",
        date: "2024-03-19",
      },
      {
        id: 3,
        title: "Lighthouse",
        imgSrc: myImage,
        description: "Latest news and updates from Lighthouse.",
        date: "2024-03-16",
      },
    ],
  },
  "iCube News Letters": {
    cards: [
      {
        id: 1,
        title: "March 2024 Newsletter",
        imgSrc: myImage,
        description:
          "Monthly roundup of key developments and industry insights.",
        date: "2024-03-15",
      },
      {
        id: 2,
        title: "Special Edition: Digital Transformation",
        imgSrc: myImage,
        description:
          "Focus on technological advancements in financial services.",
        date: "2024-03-12",
      },
      {
        id: 3,
        title: "iCube News Letters",
        imgSrc: myImage,
        description: "Latest news and updates from iCube.",
        date: "2024-03-10",
      },
    ],
  },
};

const POPULAR_PUBLICATIONS_LIMIT = 5;

const PublicationDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { publication, allPublications = [] } = location.state || {};

  const popularPublications = React.useMemo(() => {
    if (!allPublications?.length) return [];
    const otherPublications = allPublications.filter(
      (pub) => pub.title !== publication.title
    );
    return otherPublications
      .sort(() => Math.random() - 0.5)
      .slice(0, POPULAR_PUBLICATIONS_LIMIT);
  }, [allPublications, publication?.title]);

  const truncateText = (text, wordLimit = 10) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const currentPublicationCards =
    publicationDetails[publication?.title]?.cards || [];

  return (
    <>
      <Nav />
      <div className="publication-detail">
        <div className="content-wrapper">
          <div className="main-content">
            <div className="detail-header">
              <div className="header-image">
                <img src={publication.imgSrc} alt={publication.title} />
              </div>
              <div className="header-content">
                <h1 className="main-title">{publication.title}</h1>
                <p className="description">{publication.description}</p>
              </div>
            </div>
            <div className="pop-sidebar-con">
              <div className="pop-sidebar">
                <h3>Popular Publications</h3>
                <div className="popular-publications">
                  {popularPublications.length > 0 ? (
                    popularPublications.map((pub) => (
                      <div
                        key={pub.title}
                        className="popular-pub-item"
                        onClick={() =>
                          navigate("/publication-detail", {
                            state: { publication: pub, allPublications },
                          })
                        }
                      >
                        <img src={pub.imgSrc} alt={pub.title} />
                        <h4>{pub.title}</h4>
                      </div>
                    ))
                  ) : (
                    <p>No related publications available</p>
                  )}
                </div>
              </div>
              <div className="con" style={{ marginBottom: "0%" }}>
                <h2>Latest {publication.title}</h2>
                <div className="con-grid">
                  {currentPublicationCards.map((card) => (
                    <div key={card.id} className="con-item">
                      <img
                        className="con-img"
                        src={card.imgSrc}
                        alt={card.title}
                      />
                      <div className="con-content">
                        <div className="con-txt-1">
                          <p>{card.title}</p>
                        </div>
                        <div className="con-txt-2">
                          <p>{truncateText(card.description)}</p>
                        </div>
                        <p className="card-date">{card.date}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PublicationDetail;
