import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import TeamPage from "./pages/TeamPage";
import MemberPage from "./pages/MemberPage";
import ServicePage from "./pages/ServicePage";
import ProductPage from "./pages/ProductPage";
import PublicationPage from "./pages/PublicationPage";
import PublicationDetail from "./components/PublicationDetail";
import Events from "./pages/EventsPage";
import YourDataPage from "./pages/YourDataPage";
import GalleryPage from "./pages/GalleryPage";
import YearPage from "./pages/YearPage";
import ContactUsPage from "./pages/ContactUsPage";
import UserLogin from "./pages/UserLogin";
import AdminLogin from "./components/AdminLogin";
import AdminControl from "./pages/AdminControlPage";
import AdminBlog from "./pages/AdminBlog";
import AdminSchedule from "./pages/AdminSchedule";
import AdminGallery from "./pages/AdminGallery";
import AdminTeam from "./pages/AdminTeampage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/publications" element={<PublicationPage />} />
        <Route path="/publication-detail" element={<PublicationDetail />} />
        <Route path="/events" element={<Events />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/member/:id" element={<MemberPage />} />
        <Route path="/data" element={<YourDataPage />} />
        <Route path="/product/:id" element={<ProductPage />} />
        <Route path="/year" element={<YearPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admincontrol" element={<AdminControl />} />
        <Route path="/adminblog" element={<AdminBlog />} />
        <Route path="/adminschedule" element={<AdminSchedule />} />
        <Route path="/admingallery" element={<AdminGallery />} />
        <Route path="/adminteam" element={<AdminTeam />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
