import React, { useState } from "react";
import img1 from "../static/img/20221.jpeg";
import img2 from "../static/img/20222.jpeg";
import img3 from "../static/img/20223.jpeg";
import img4 from "../static/img/20224.jpeg";
import img5 from "../static/img/20225.jpeg";
import img6 from "../static/img/20226.jpeg";

const images = [
  { id: 1, src: img6, alt: "Award Image 1", text: "Award 1" },
  { id: 2, src: img5, alt: "Award Image 2", text: "Award 2" },
  { id: 3, src: img4, alt: "Award Image 3", text: "Award 3" },
  { id: 4, src: img3, alt: "Award Image 4", text: "Award 4" },
  { id: 5, src: img2, alt: "Award Image 5", text: "Award 5" },
  { id: 6, src: img1, alt: "Award Image 6", text: "Award 6" },
];

const AwardImages = () => {
  const [selectedImg, setSelectedImage] = useState(null);
  const [selectedText, setSelectedText] = useState("");

  const openImage = (src, text) => {
    setSelectedImage(src);
    setSelectedText(text);
  };

  const closeImage = () => {
    setSelectedImage(null);
    setSelectedText("");
  };

  return (
    <div className="image-container award-container">
      {images.map((img) => (
        <div key={img.id} className="image-wrapper">
          <div className="image-text">{img.text}</div>
          <img
            src={img.src}
            alt={img.alt}
            className="carousel-image"
            onClick={() => openImage(img.src, img.text)}
          />
        </div>
      ))}

      {selectedImg && (
        <div className="full-img-overlay" onClick={closeImage}>
          <div>{selectedText}</div>
          <img
            src={selectedImg}
            alt="Full Screen"
            style={{ maxWidth: "90%", maxHeight: "90%" }}
          />{" "}
        </div>
      )}
    </div>
  );
};

export default AwardImages;
