import React, { useState } from "react";
import "../static/css/AdminLogin.css";

function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ischecked, setIsChecked] = useState(false);

  const handlesubmit = (e) => {};

  return (
    <>
      <div className="AdminLogin-container">
        <div className="AdminLogin-box">
          <h3>Admin Login</h3>
          <form>
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <label
              htmlFor="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            >
              Password
            </label>
            <input type="password" />
            <div className="checkbox-container">
              <input
                type="checkbox"
                checked={ischecked}
                onChange={(e) => {
                  setIsChecked(e.target.checked);
                }}
              />
              <label htmlFor="checkbox">Remember me</label>
            </div>
            <button onClick={(e) => handlesubmit(e)}>Login</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
