import React from 'react';
import myImage from '../static/img/data.jpeg';
import '../static/css/Yourdata.css';

const datas = [
  { title: 'Regulatory Reports', imgSrc: myImage },
  { title: 'Downloads', imgSrc: myImage },
  { title: 'Audit Documents', imgSrc: myImage },
  { title: 'Lighthouse', imgSrc: myImage },
];

const data = () => {
  return (
    <div className="data-container">
      <h2>Your Data</h2>
      <div className="grid">
        {datas.map((data, index) => (
          <div key={index} className="data-item">
            <img className='data-img' src={data.imgSrc} alt={data.title} />
            <p>{data.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default data;
