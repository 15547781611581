import React, { useState } from "react";
import AdminNav from "../components/AdminNav";
import AdminHeader from "../components/AdminHeader";
import "../static/css/AdminSchedule.css";
function PublicationPage() {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [event, setEvent] = useState("");
  return (
    <>
      <div className="Admin-mainbox">
        <AdminNav />
        <div className="Admin-contentbox">
          <AdminHeader />
          <div className="admincontrol-form">
            <form>
              <label htmlFor="date">Date</label>
              <div className="input-with-icon">
                <input
                  type="date"
                  id="date"
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                />
              </div>
              <label htmlFor="time">Time</label>
              <div className="input-with-icon">
                <input
                  type="time"
                  id="time"
                  placeholder="hdfjbsajgbejhbfhds"
                  onChange={(e) => {
                    setTime(e.target.value);
                  }}
                />
              </div>
              <label htmlFor="Event">Event</label>
                <input
                  type="text"
                  id="thumbnail"
                  placeholder=""
                  onChange={(e) => {
                    setEvent(e.target.value);
                  }}
                />
              <button>Update</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default PublicationPage;
