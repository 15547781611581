import React from "react";
import Slider from "react-slick";
import star from "../static/img/star.png";
import "slick-carousel/slick/slick.css";
import "../static/css/Testimonials.css";

function Testimonials() {
  const testimonials = [
    {
      header: "Trustworthy, thorough, and always reliable!",
      text: "“Working with SKP & Co. has transformed our financial operations. Their insights helped us streamline processes, cut costs, and stay fully compliant—giving us the confidence to focus on growth.”",
      Name: "Jack",
      role: "CEO - XYZ Company",
    },
    {
      header: "Making sense of numbers so you can focus on what matters.",
      text: "“We've seen remarkable improvements in our financial clarity and compliance since partnering with SKP & Co. It's taken the stress out of financial decisions and given us more confidence in our next steps.”",
      Name: "Jack",
      role: "CEO - XYZ Company",
    },
    {
      header: "Financial Clarity, Compliance, and Confidence Delivered.",
      text: "“XXX and his team at SKP & Co have been instrumental in our auditing process, delivering exceptional value and significant improvements over the past year. Their support has helped us minimize waste and reallocate resources effectively, even as many schools face budget cuts. I highly recommend SKP & Co for auditing and financial consultancy.”",
      Name: "Jack",
      role: "CEO - XYZ Company",
    },
    {
      header: "Don't take our word for it. Take theirs",
      text: "“The professionalism and warmth displayed by XXX have been truly remarkable. As a very small business, we recognize that you typically work with much larger clients, yet you have consistently made us feel valued and important. Your first-class service has exceeded our expectations and is a testament to your dedication to the profession.”",
      Name: "Jack",
      role: "CEO - XYZ Company",
    },
  ];

  const LeftArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={`${className} custom-arrow`} onClick={onClick}>
        <svg
          width="22"
          height="38"
          viewBox="0 0 22 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.3333 37.3333L0 19L18.3333 0.666626L21.5875 3.92079L6.50833 19L21.5875 34.0791L18.3333 37.3333Z"
            fill="#1C1B1F"
          />
        </svg>
      </div>
    );
  };

  const RightArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={`${className} custom-arrow`} onClick={onClick}>
        <svg
          width="22"
          height="38"
          viewBox="0 0 22 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.66667 0.666706L22 19L3.66667 37.3334L0.412498 34.0792L15.4917 19L0.412498 3.92087L3.66667 0.666706Z"
            fill="#1C1B1F"
          />
        </svg>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
  };

  return (
    <div className="testimonials-section">
      <div className="testimonials-box">
        <p>Client Testimonials</p>
        <div className="testimonials-content">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-slide" key={index}>
                <div className="testimonial-stars">
                  {[...Array(5)].map((_, i) => (
                    <img
                      src={star}
                      alt="Star"
                      className="testimonial-star"
                      key={i}
                    />
                  ))}
                </div>
                <p>{testimonial.header}</p>
                <span>{testimonial.text}</span>
                <div className="testimonial-content-text">
                  <p>{testimonial.Name}</p>
                  <span>{testimonial.role}</span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
