import React from "react";
import Login from "../components/Login";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
function UserLogin() {

  return (
    <> 
    <Nav />
    <Login />
    <Footer/>
    </>
  )
}
export default UserLogin;