import React,{useState} from "react";
import "../static/css/Intro.css";
import {Link} from "react-router-dom";
import intro_image from "../static/img/Intro.jpeg"

function Intro() {

  return (
    <>
        <div className="Intro-container-1">
            <div className="Intro-box-1">
                <div className="details-intro">
                    <h4>Trust. Thrive. Partner with SKP & Co</h4>
                    <p>At SKP & Co., we're more than just auditors. We're your trusted partner in navigating the complexities of the financial landscape. Our team provides comprehensive auditing services that ensure the accuracy and integrity of your financial statements. </p>
                    <Link to="/team"><button>About Us <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.175 9.71997H0V7.71997H12.175L6.575 2.11997L8 0.719971L16 8.71997L8 16.72L6.575 15.32L12.175 9.71997Z" fill="#F8F8F9"/>
                    </svg></button></Link>
                </div>
                <div className="image">
                    <img src={intro_image} alt="intro image" />
                </div>
            </div>
        </div>
    </>
  )
}

export default Intro;