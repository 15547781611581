import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../static/css/Login.css";

function Login() {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handlesubmit = (e) => {};

  return (
    <>
      <div className="login-container">
        <div className="login-box">
          <h4>User Login</h4>
          <form className="login-form">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              onChange={(e) => {
                setMail(e.target.value);
              }}
            />
            <label htmlFor="password">Password</label>
            <input
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <div className="login-checkbox">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={(e) => {
                  setIsChecked(e.target.checked);
                }}
              />
              <label htmlFor="rememberMe">Remember me</label>
            </div>
            <button
              onClick={(e) => {
                handlesubmit(e);
              }}
            >
              sign up
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
