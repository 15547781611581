import React from "react";
import Gallery from "../components/Gallery";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

function GalleryPage() {
  return (
    <>
      <Nav />
      <Gallery />
      <Footer />
    </>
  );
}
export default GalleryPage;
