import React, { useState } from "react";
import "../static/css/ServiceHeader.css";

const ServiceHeader = ({ image, title, description }) => {
  return (
    <>
      <div className="service-header-img">
        <div className="serhead-container">
          <div className="serviceheader-content">
            <h4>{title}</h4>
            <hr className="serhead-line" />
            <p>{description}</p>
          </div>
          <img src={image} alt="servicehead-image" />
        </div>
      </div>
    </>
  );
};

export default ServiceHeader;
