import React, { useState } from "react";
import img1 from "../static/img/20221.jpeg";
import img2 from "../static/img/20222.jpeg";
import img3 from "../static/img/20223.jpeg";
import img4 from "../static/img/20224.jpeg";
import img5 from "../static/img/20225.jpeg";
import img6 from "../static/img/20226.jpeg";

const images = [
  { id: 1, src: img1, alt: "Seminar Image 1", text: "Seminar 1" },
  { id: 2, src: img2, alt: "Seminar Image 2", text: "Seminar 2" },
  { id: 3, src: img3, alt: "Seminar Image 3", text: "Seminar 3" },
  { id: 4, src: img4, alt: "Seminar Image 4", text: "Seminar 4" },
  { id: 5, src: img5, alt: "Seminar Image 5", text: "Seminar 5" },
  { id: 6, src: img6, alt: "Seminar Image 6", text: "Seminar 6" },
];

const SeminarImages = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedText, setSelectedText] = useState("");

  const openImage = (src, text) => {
    setSelectedImage(src);
    setSelectedText(text);
  };

  const closeImage = () => {
    setSelectedImage(null);
    setSelectedText("");
  };

  return (
    <div className="image-container seminar-container">
      {images.map((img) => (
        <div key={img.id} className="image-wrapper">
          <div className="image-text">{img.text}</div>
          <img
            src={img.src}
            alt={img.alt}
            className="carousel-image"
            onClick={() => openImage(img.src, img.text)}
          />
        </div>
      ))}

      {selectedImage && (
        <div className="full-img-overlay" onClick={closeImage}>
          <div>
            {selectedText}
          </div>
          <img
            src={selectedImage}
            alt="Full Screen"
            style={{ maxWidth: "90%", maxHeight: "90%" }}
          />{" "}
        </div>
      )}
    </div>
  );
};

export default SeminarImages;
