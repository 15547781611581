import React, { useState } from "react";
import AnniversaryGallery from "../components/AnniversaryGallery.jsx";
import SeminarGallery from "../components/SeminarGallery.jsx";
import AwardGallery from "../components/AwardGallery.jsx";
import "../static/css/Gallery.css";

const ImageCarousel = () => {
  const [activeEvent, setActiveEvent] = useState("anniversary");

  return (
    <div className="carousel-container">
      <div className="carousel-container-buttons">
        <div className="gallery-btns">
          <button
            className={`anniversary-btn ${
              activeEvent === "anniversary" ? "active" : ""
            }`}
            onClick={() => setActiveEvent("anniversary")}
          >
            Anniversary
          </button>
          <button
            className={`seminar-btn ${
              activeEvent === "seminar" ? "active" : ""
            }`}
            onClick={() => setActiveEvent("seminar")}
          >
            Seminar
          </button>
          <button
            className={`award-btn ${
              activeEvent === "award" ? "active" : ""
            }`}
            onClick={() => setActiveEvent("award")}
          >
            Award
          </button>
        </div>
      </div>

      <div className="yearly-images">
        {activeEvent === "anniversary" && <AnniversaryGallery />}
        {activeEvent === "seminar" && <SeminarGallery />}
        {activeEvent === "award" && <AwardGallery />}
      </div>
    </div>
  );
};

export default ImageCarousel;
