import React, { useState } from "react";
import "../static/css/AdminHeader.css";

function AdminHeader() {

  const handlesubmit = (e) => {};

  return (
    <>
      <div className="adminhead-container">
        <svg
          width="39"
          height="34"
          viewBox="0 0 39 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.9417 18.8333H9.66667V15.1667H31.9417L29.1 12.325L31.6667 9.66667L39 17L31.6667 24.3333L29.1 21.675L31.9417 18.8333ZM22.5 11.5V4.16667H4.16667V29.8333H22.5V22.5H26.1667V29.8333C26.1667 30.8417 25.8076 31.7049 25.0896 32.4229C24.3715 33.141 23.5083 33.5 22.5 33.5H4.16667C3.15833 33.5 2.29514 33.141 1.57708 32.4229C0.859028 31.7049 0.5 30.8417 0.5 29.8333V4.16667C0.5 3.15833 0.859028 2.29514 1.57708 1.57708C2.29514 0.859028 3.15833 0.5 4.16667 0.5H22.5C23.5083 0.5 24.3715 0.859028 25.0896 1.57708C25.8076 2.29514 26.1667 3.15833 26.1667 4.16667V11.5H22.5Z"
            fill="white"
          />
        </svg>
      </div>
    </>
  );
}

export default AdminHeader;
