import React from "react";
import Intro from "../components/Intro";
import Services from "../components/Services";
import Testimonials from "../components/Testimonials";
import Connect from "../components/Connect";
import Header from "../components/Header";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
function Home() {
  return (
    <>
      <Nav/>
      <Header />
      <Intro />
      <Services />
      <Testimonials />
      <Connect />
      <Footer />
    </>
  );
}
export default Home;
