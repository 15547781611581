import React, { useState } from "react";
import img1 from "../static/img/20221.jpeg";
import img2 from "../static/img/20222.jpeg";
import img3 from "../static/img/20223.jpeg";
import img4 from "../static/img/20224.jpeg";
import img5 from "../static/img/20225.jpeg";
import img6 from "../static/img/20226.jpeg";

const imagesByYear = {
  2024: [
    { id: 1, src: img1, alt: "Anniversary Image 1" },
    { id: 2, src: img2, alt: "Anniversary Image 2" },
    { id: 3, src: img3, alt: "Anniversary Image 3" },
    { id: 4, src: img4, alt: "Anniversary Image 4" },
    { id: 5, src: img5, alt: "Anniversary Image 5" },
    { id: 6, src: img6, alt: "Anniversary Image 6" },
    { id: 7, src: img6, alt: "Anniversary Image 1" },
    { id: 8, src: img5, alt: "Anniversary Image 2" },
    { id: 9, src: img4, alt: "Anniversary Image 3" },
    { id: 10, src: img3, alt: "Anniversary Image 4" },
    { id: 11, src: img2, alt: "Anniversary Image 5" },
    { id: 12, src: img1, alt: "Anniversary Image 6" },
  ],
  2023: [
    { id: 1, src: img6, alt: "Anniversary Image 1" },
    { id: 2, src: img5, alt: "Anniversary Image 2" },
    { id: 3, src: img4, alt: "Anniversary Image 3" },
    { id: 4, src: img3, alt: "Anniversary Image 4" },
    { id: 5, src: img2, alt: "Anniversary Image 5" },
    { id: 6, src: img1, alt: "Anniversary Image 6" },
  ],
};

const AnniversaryImages = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const openPopup = (image, year) => {
    setSelectedImage(image);
    setSelectedYear(year);
  };

  const closePopup = () => {
    setSelectedImage(null);
    setSelectedYear(null);
  };

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className="image-container seminar-container">
      {Object.keys(imagesByYear).map((year) => (
        <div key={year} className="image-wrapper">
          <div className="image-text">{year}</div>
          <img
            src={imagesByYear[year][0].src}
            alt={imagesByYear[year][0].alt}
            className="carousel-image"
            onClick={() => openPopup(imagesByYear[year][0].src, year)}
          />
        </div>
      ))}

      {selectedImage && (
        <div className="full-img-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedImage}
              alt="Selected"
              className="popup-main-image"
            />
            <div className="thumbnail-row">
              {imagesByYear[selectedYear].map((img) => (
                <img
                  key={img.id}
                  src={img.src}
                  alt={img.alt}
                  className="thumbnail"
                  onClick={() => handleThumbnailClick(img.src)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnniversaryImages;
