import React, { useState } from "react";
import AdminNav from "../components/AdminNav";
import AdminHeader from "../components/AdminHeader";
import "../static/css/AdminBlog.css";
function PublicationPage() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  return (
    <>
      <div className="Admin-mainbox">
        <AdminNav />
        <div className="Admin-contentbox">
          <AdminHeader />
          <div className="admincontrol-form">
            <form>
              <label htmlFor="title">Title</label>
              <input
                type="text"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <label htmlFor="content">Content</label>
              <div className="file-upload">
                <input
                  type="file"
                  id="content"
                  onChange={(e) => {
                    setContent(e.target.value);
                  }}
                />
                <span className="file-upload-placeholder">
                  <i className="upload-icon">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.66659 21.6667V10.8L6.19992 14.2667L4.33325 12.3333L10.9999 5.66668L17.6666 12.3333L15.7999 14.2667L12.3333 10.8V21.6667H9.66659ZM0.333252 7.00001V3.00001C0.333252 2.26668 0.594363 1.6389 1.11659 1.11668C1.63881 0.594455 2.26659 0.333344 2.99992 0.333344H18.9999C19.7333 0.333344 20.361 0.594455 20.8833 1.11668C21.4055 1.6389 21.6666 2.26668 21.6666 3.00001V7.00001H18.9999V3.00001H2.99992V7.00001H0.333252Z"
                        fill="#828282"
                      />
                    </svg>
                  </i>
                  Upload File
                </span>
              </div>
              <label htmlFor="thumbnail">Thumbnail</label>
              <div className="file-upload">
                <input
                  type="file"
                  id="thumbnail"
                  onChange={(e) => {
                    setThumbnail(e.target.value);
                  }}
                />
                <span className="file-upload-placeholder">
                  <i className="upload-icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.66667 24C1.93333 24 1.30556 23.7389 0.783333 23.2167C0.261111 22.6944 0 22.0667 0 21.3333V2.66667C0 1.93333 0.261111 1.30556 0.783333 0.783333C1.30556 0.261111 1.93333 0 2.66667 0H21.3333C22.0667 0 22.6944 0.261111 23.2167 0.783333C23.7389 1.30556 24 1.93333 24 2.66667V21.3333C24 22.0667 23.7389 22.6944 23.2167 23.2167C22.6944 23.7389 22.0667 24 21.3333 24H2.66667ZM2.66667 21.3333H21.3333V2.66667H2.66667V21.3333ZM4 18.6667H20L15 12L11 17.3333L8 13.3333L4 18.6667Z"
                        fill="#828282"
                      />
                    </svg>
                  </i>
                  Upload Image
                </span>
              </div>
              <button>Update</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default PublicationPage;
