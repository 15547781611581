import React from "react";
import Yourdata from "../components/Yourdata";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
function YourDataPage() {
  return (
    <>
    <Nav/>
    <Yourdata/>
    <Footer />
    </>
  )
}
export default YourDataPage;