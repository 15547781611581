import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import "../static/css/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="dir">
        <div className="sec">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.194385061706!2d77.00348122512658!3d11.024037689140181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8598ffc9c58f3%3A0x1538616328cef447!2sMayflower%20signature!5e0!3m2!1sen!2sin!4v1717484414582!5m2!1sen!2sin"></iframe>

          <h3>Contact Us</h3>
          <p>
            CENTRAL OFFICE
            <br />
            849/2, 2nd floor, Mettupalayam Road,
            <br />
            Poo Market,
            <br />
            Coimbatore – 641002.
          </p>
          <br />
          <p>Phone: +91 9787597500</p>
          <p>Email: info@skpandco.in</p>
        </div>
        <div className="sec">
          <h3>Quick Links</h3>
          <div className="icons">
            <a href="/">
              <FaLinkedin />
            </a>
            <a href="/">
              {" "}
              <FaInstagram />
            </a>
            <a href="/">
              <FaTwitter />
            </a>
            <a href="/">
              <FaFacebook />
            </a>
          </div>
        </div>
        <div className="sec">
          <h3>Pages</h3>
          <ul>
            <li>
              <Link to="/">
                <span>Home</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/team">
                <span>Our Team</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/services">
                <span>Services</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/publications">
                <span>Publications</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/events">
                <span>Events & Calendar</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/gallery">
                <span>Gallery</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/contact">
                <span>Contact Us</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="sec">
          <h3>Services</h3>
          <ul>
            <li>
              <Link to="/services/alternative-dispute-resolution">
                <span>Alternative Dispute Resolution</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/services/corporate-compliance-management">
                <span>Corporate Compliance Management</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/services/legal-management-representation">
                <span>Legal Management and Representation</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/services/transaction-advisory">
                <span>Transaction Advisory</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/services/due-diligence-audits">
                <span>Due Diligence Audits</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/services/intellectual-property-rights">
                <span>Intellectual Property Rights</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/services/corporate-regulatory-risk-assessment">
                <span>Corporate Regulatory Risk Assessment and Advisory Services</span>
              </Link>
            </li>
            <br />
            <li>
              <Link to="/services/insolvency-bankruptcy-practice">
                <span>Insolvency and Bankruptcy Practice</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="social">
        <div className="social-text">
          <div className="s_align">
            <a href="">privacy policy</a>
          </div>
          <div className="s_align">
            <p>© 2024 SKP & Co</p>
          </div>
          <div className="s_align">
            <a href="">Terms & Conditions</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
