import React from "react";
import { useNavigate } from "react-router-dom";
import myImage from "../static/img/data.jpeg";
import pub_header from "../static/img/publications.jpg";
import "../static/css/publications.css";

const publications = [
  {
    title: "Daily Regulatory Updates",
    imgSrc: myImage,
    description:
      "Stay up-to-date with the latest regulatory changes and compliance requirements in the industry.",
    date: "2024-01-01",
    link: "/publication-detail",
  },
  {
    title: "Downloads",
    imgSrc: myImage,
    description:
      "Access and download important documents, forms, and resources for your reference.",
    date: "2024-01-01",
    link: "/publication-detail",
  },
  {
    title: "IAFA News",
    imgSrc: myImage,
    description:
      "Get the latest news and updates from the Insurance and Financial Advisers Association.",
    date: "2024-01-01",
    link: "/publication-detail",
  },
  {
    title: "Lighthouse",
    imgSrc: myImage,
    description:
      "Our flagship publication providing in-depth analysis and insights on industry trends.",
    date: "2024-01-01",
    link: "/publication-detail",
  },
  {
    title: "iCube News Letters",
    imgSrc: myImage,
    description:
      "Regular newsletters featuring curated content, updates and developments in the financial sector.",
    date: "2024-01-01",
    link: "/publication-detail",
  },
  {
    title: "IAFA News",
    imgSrc: myImage,
    description:
      "Additional coverage of IAFA activities, events and important announcements.",
    date: "2024-01-01",
    link: "/publication-detail",
  },
];

const Publications = () => {
  const navigate = useNavigate();

  const handleReadMore = (publication) => {
    navigate("/publication-detail", { 
      state: { 
        publication,
        allPublications: publications
      } 
    });
  };

  const truncateText = (text, wordLimit = 10) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <>
      <div className="publications">
        <div className="pub-header">
          <div className="pub-header-txt"></div>
          <div className="pub-header-img">
            <img src={pub_header} alt="" />
          </div>
        </div>
        <div className="pub-header-text">
          <h1>Publications</h1>
          <p>
            Explore our collection of regulatory updates, newsletters, and
            industry insights to stay informed about the latest developments in
            the field.
          </p>
          <p>
            Our publications cover a wide range of topics including daily
            regulatory changes, industry analysis, market trends, and expert
            commentary. Whether you're a professional seeking to stay compliant
            or an enthusiast wanting to deepen your knowledge, our resources are
            designed to keep you well-informed.
          </p>
        </div>
        <div className="con">
          <h2>Our Publications</h2>
          <div className="con-grid">
            {publications.map((publication, index) => (
              <div key={index} className="con-item">
                <img
                  className="con-img"
                  src={publication.imgSrc}
                  alt={publication.title}
                />
                <div className="con-content">
                  <div className="con-txt-1">
                    <p>{publication.title}</p>
                  </div>
                  <div className="con-txt-2">
                    <p>{truncateText(publication.description)}</p>
                  </div>
                  <button
                    className="read-more-btn"
                    onClick={() => handleReadMore(publication)}
                  >
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Publications;
